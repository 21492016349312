<template>
    <b-nav-item-dropdown ref="dropdown" class="dropdown-cart mr-25" menu-class="dropdown-menu-media" right>
        <template #button-content>
            <feather-icon class="text-body" icon="LogInIcon" size="21" />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                    Cambio de Identidad Autenticado
                </h4>
                <!-- <b-badge pill variant="light-primary">
                    {{ items.length }} Usuarios
                </b-badge> -->
            </div>
        </li>

        <!-- User Items -->
        <vue-perfect-scrollbar v-if="!loading" :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area"
            tagname="li">
            <b-media v-for="item in items" :key="item.user.fullName">
                <template #aside>
                    <b-avatar rounded-circle size="42" :src="null" :text="item.user.userName"
                        :style="{ backgroundColor: item.avatarColor }" />
                </template>
                <div class="media-heading">
                    <h6 class="cart-item-title">
                        <b-link class="text-body">
                            {{ item.user.fullName }}
                        </b-link>
                    </h6>
                    <small class="cart-item-by_ text-mutted">{{ item.roles.map(r => r).join(',') }}</small>
                </div>

                <div class="cart-item-qty ml-1">
                    <b-button :disabled="loading" size="sm" variant="outline-primary" @click="impersonate(item)">
                        Ingresar
                    </b-button>
                </div>

                <!-- <h5 class="cart-item-price">
                    0.00
                </h5> -->
            </b-media>
        </vue-perfect-scrollbar>

        <!-- Cart Footer -->
        <p v-if="loading" class="m-0 p-1 text-center">
            <b-spinner small variant="primary" /> Espere...
        </p>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton, BAvatar, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import store from '@/store'
import router from '@/router'
import UsersProvider from '@/providers/Users'
const UsersResource = new UsersProvider()

import AuthProvider from '@/providers/Auth'
const AuthResource = new AuthProvider()

import WareHousesProvider from '@/providers/WareHouses'
const WareHousesResource = new WareHousesProvider()

export default {
    name: 'ImpersonateDropdown',
    components: {
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BImg,
        BFormSpinbutton,
        VuePerfectScrollbar,
        BButton,
        BAvatar,
        BSpinner,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            loading: false,
            items: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
        }
    },
    computed: {
        isAdministrator() {
            return store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol']
        },
        isSuperCash() {
            return store.getters['auth/getUser'].customRol.id == store.getters['auth/getSuperCashierRol']
        },
        
    },
    async mounted() {
        await this.getUsers()
    },
    methods: {
        getRandomColor() {
            const colors = [$themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.secondary, $themeColors.warning, $themeColors.primary];
            return colors[Math.floor(Math.random() * colors.length)];
        },
        async getUsers() {
            const oldUser = store.getters['auth/getUser']
            const { data } = await UsersResource.index()
            this.items = data.filter(u => u.user.id !== oldUser.id).map(user => ({
                ...user,
                avatarColor: this.getRandomColor()
            }))
        },
        async impersonate(user) {

            const branch = store.getters['auth/getBranch']
            const oldUser = store.getters['auth/getUser']
            const formData = {
                userId: user.user.id.toString(),
                sucursalId: branch.sucursal_Id,
                empresaId: store.getters['auth/getConexionId'],
                deviceId: store.getters['auth/getDeviceId']
            }
            this.loading = true

            try {

                const { data } = await AuthResource.impersonate(formData)

                if (data.isSuccesful) {
                    this.$nextTick(() => {
                        this.$refs.dropdown.hide()
                    })

                    //Seteo el old user
                    store.dispatch('auth/UPDATE_IMPERSONATE', {
                        userId: oldUser.id,
                        sucursalId: formData.sucursalId,
                        empresaId: formData.empresaId,
                    })

                    store.dispatch('auth/UPDATE_IS_IMPERSONATE', true)

                    //Seteamos el nuevo user
                    store.commit('auth/SET_IS_AUTHENTICATED', true)
                    store.commit('auth/SET_TOKEN', { ...data.data })
                    store.commit('auth/SET_REFRESH_TOKEN', data.data)
                    store.commit('auth/SET_CURRENT_USER', data.data)
                    store.commit('auth/SET_PERMISSIONS', data.data.permisos)
                    if (data.data) {
                        if (data.data.customRol.id == "849fb91e-cab4-4894-a1c3-aedf03b47c34" || data.data.customRol.id == "2d7f49c8-ad48-4c4f-8def-fb06fc68143c") {
                            store.commit('auth/SET_ADMINISTRATOR', true)
                        }
                    }

                    window.user = data.data
                    await this.getSettingByCompanyId(data.data.empresaId)
                    await this.getWareHouses()
                    await this.getUsers()

                    this.$nextTick(() => {
                        this.success(`Bienvenido ${data.data.userName}`);                        
                        const wareHouses = store.getters['auth/getWareHouses']
                        let nuevoAlmacen = 0;
                        if (store.getters['auth/getUser'].almacenes.length > 0) {
                            const wareHouse = wareHouses.find(wh => wh.almacen_Id == store.getters['auth/getUser'].almacenes[0].almacen_Id)
                            nuevoAlmacen = wareHouse ? wareHouse.almacen_Id : 0
                        }

                        if (this.isSuperCash) {
                            nuevoAlmacen = 0
                        }
                        
                        store.dispatch('auth/UPDATE_WAREHOUSE_ID', nuevoAlmacen) 

                        if (router.currentRoute.name !== 'home') {
                            router.push({ name: 'home' })
                        }
                    })
                    
                } else {
                    this.danger(data.message)
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        async getSettingByCompanyId(empresId) {
            const { data } = await AuthResource.getSettingByCompanyId(empresId)
            if (data.isSuccesful) {
                store.commit('auth/SET_SETTING', data.data)
            }
        },
        async getWareHouses() {
            const { data } = await WareHousesResource.index()
            if (data.isSuccesful) {
                store.commit('auth/SET_WAREHOUSES', data.data)
            }
            
        }
    },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
    .media {
        .media-aside {
            align-items: center;
        }
    }
}
</style>