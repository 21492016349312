<template>
	<div>
		<b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
			<template #button-content>
				<feather-icon :badge="countMaintenances" class="text-body" icon="TruckIcon" size="21" />
			</template>

			<!-- Header -->
			<li class="dropdown-menu-header">
				<div class="dropdown-header d-flex">
					<h4 class="notification-title mb-0 mr-auto">
						Mantenimientos
					</h4>
					<b-button v-if="canRequestMaintenance" @click="isActiveModalAddMaintenance = true" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
						size="sm">
						<feather-icon icon="PlusIcon" /> Solicitar
					</b-button>
				</div>
			</li>

			<!-- Notifications -->
			<vue-perfect-scrollbar :settings="perfectScrollbarSettings"
				class="scrollable-container media-list scroll-area" tagname="li">				
				<div v-for="(group, truckId) in groupedMaintenances" :key="truckId">
					<b-link @click="openMaintenancesModal(group)"
							class="d-block mb-1 vehicle-group"
							:class="{ 'active-group': group.showDetails }">					
						<b-media>
							<template #aside>
								<b-avatar size="32" :variant="getVariant(group.maintenances[0].tag)">
									<feather-icon icon="TruckIcon" />
								</b-avatar>
							</template>
							
							<p class="media-heading">
								<span class="font-weight-bolder">
									{{ group.maintenances[0].identifier }}
								</span>
								<b-badge v-if="group.maintenances.length > 1" variant="light-primary" pill class="ml-1_">
									{{ group.maintenances.length }} mantenimientos pendientes
								</b-badge>
							</p>
							
							<small class="notification-text">{{ group.maintenances[0].description }}</small><br>
							<small class="text-capitalize">
							<feather-icon icon="UserIcon" /> {{ group.maintenances[0].created_by }}
							<feather-icon icon="ClockIcon" class="ml-1" /> 
							{{ group.maintenances[0].updated_at_human }}
							</small>
							
							<b-badge pill :variant="getVariantMaintenance(group.maintenances[0].type)" class="text-capitalize ml-1">
								<feather-icon :icon="group.maintenances[0].type === 'Preventivo' ? 'CalendarIcon' : 'ToolIcon'"/> 
								{{ group.maintenances[0].type }}
							</b-badge>
						</b-media>
					</b-link>
				</div>
			</vue-perfect-scrollbar>
		</b-nav-item-dropdown>

		<!-- Modal Add maintenance -->
		<modal-add-maintenance :active.sync="isActiveModalAddMaintenance" :trucks="trucks"/>
		<b-modal ref="refModalMaintenance" hide-footer title="Solicitar Mantenimiento" no-close-on-esc no-close-on-backdrop
		size="sm">
			<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
				<b-row>
					<b-col cols="12">
						<b-form-group label="Unidad" :description="statusTruckActuall == 'En mantenimiento' ? 'Actualmente esta en mantenimiento' : ''">
							<v-select id="truck_id" v-model="form.truck_id" :options="trucks"
                                label="label" :reduce="option => option.id" :clearable="false"
                                class="select-size-sm"
                                placeholder="Selecciona una unidad"
								@input="selectTruck">
								<template #option="{ disabled, estatus, label }">
									<div :class="{ 'text-red-700': disabled }">
										{{ label }}
										<span v-if="estatus === 'En mantenimiento'" class="text-xs text-bold text-gray-500">
											<br>En mantenimiento
										</span>
									</div>
								</template>
							</v-select>
						</b-form-group>
					</b-col>

					<b-col cols="12" md="12" sm="12">
						<b-form-group label="Kilometraje" description="Último Kilometraje registrado">
							<b-form-input size="sm" id="odometer" v-model="form.odometer" type="number" placeholder="Kilometraje" />
						</b-form-group>
					</b-col>

					<b-col cols="12" md="12" sm="12">
						<b-form-group label="Tipo">
							<b-form-radio-group v-model="form.type">
								<b-form-radio value="Preventivo">Preventivo</b-form-radio>
								<b-form-radio value="Correctivo">Correctivo</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>

					<b-col cols="12" md="12">
						<b-form-group label="Prioridad">
							<b-form-radio-group v-model="form.tag">
								<b-form-radio v-for="tag in tags" :key="tag.id" :value="tag.id" :class="['custom-control-' + tag.color]">
								{{ tag.name }}
								</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>

					<!-- Descripcion del mantenimiento -->
					<b-col cols="12">
						<b-form-group label="Descripción">
							<b-form-textarea id="description" v-model="form.description" rows="3"
								placeholder="Breve descripcion sobre el mantenimiento" />
						</b-form-group>
					</b-col>
				</b-row>

				<div class="text-right">
					<b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="m-1" variant="outline-secondary" @click="hideModal">
						Cerrar
					</b-button>
					<b-button @click="addMaintenance" :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
						Guardar
					</b-button>
				</div>
			</b-overlay>
		</b-modal>

		<!-- Modal List maintenances -->
		<b-modal ref="refModalListMaintenances" hide-footer 
			:title="`Mantenimientos de ${selectedTruck ? selectedTruck.identifier : ''}`" size="lg">
			<vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container">
				<div v-for="maintenance in selectedMaintenances" :key="maintenance.id" class="mb-3">
					<b-media>
						<template #aside>
							<b-avatar size="40" :variant="getVariant(maintenance.tag)">
								<feather-icon :icon="maintenance.type === 'Preventivo' ? 'CalendarIcon' : 'ToolIcon'" />
							</b-avatar>
						</template>
					
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<!-- <p class="media-heading mb-0 font-weight-bolder"> -->
									<b-badge pill :variant="getVariant(maintenance.tag)" class="text-capitalize">
										{{ maintenance.tag }}
									</b-badge>
									
								<!-- </p> -->
								<p>
									{{ maintenance.description }}
								</p>
							</div>
							
							<div class="text-right">
								<b-badge pill :variant="getVariantMaintenance(maintenance.type)" class="text-capitalize">
									{{ maintenance.type }}
								</b-badge>
								<div class="mt-1">
									<small class="text-muted_">
									<feather-icon icon="ClockIcon" /> 
									{{ formatDate(maintenance.created_at) }}
									</small>
								</div>
							</div>
						</div>
					
						<hr class="my-1">
						
						<div class="d-flex justify-content-between _text-small">
							<div>
								<feather-icon icon="UserIcon" class="mr-1"/>
								<span>{{ maintenance.created_by }}</span>
							</div>
							<div>
								<feather-icon icon="AlertCircleIcon" class="mr-1"/>
								<span>{{ maintenance.updated_at_human }}</span>
							</div>
						</div>
					</b-media>
				</div>
			</vue-perfect-scrollbar>
		</b-modal>


	</div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import {
	BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
	BTabs, BTab, BModal, BForm, BFormGroup, BFormInput, BFormTextarea, BCard, BCardBody, BCardHeader, BRow, BCol,
	BOverlay, BFormRadioGroup, BFormSelect, BFormRadio,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { computed, nextTick, onMounted, ref } from '@vue/composition-api'
import * as helper from '@/libs/helpers';
import Swal from "sweetalert2";
import "animate.css";
import moment from 'moment'
import { isAuthenticated } from '@/libs/authHelper';
import vSelect from "vue-select";
import ModalAddMaintenance from '@core/components/app-maintenances/ModalAddMaintenance.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import WebCamionesProvider from '@/providers/WebCamiones';
const WebCamionesResource = new WebCamionesProvider();

export default {
	name: 'MaintenanceToggler',
	components: {
		BNavItemDropdown,
		BBadge,
		BMedia,
		BLink,
		BAvatar,
		VuePerfectScrollbar,
		BButton,
		BFormCheckbox,
		BTabs,
		BTab,
		BModal,
		BFormInput,
		BForm,
		BFormGroup,
		BFormTextarea,
		BCard,
		BCardBody,
		BCardHeader,
		BRow,
		BCol,
		BOverlay,
		BFormRadioGroup,
		BFormSelect,
		BFormRadio,
		
		//
		ModalAddMaintenance,
		AppTimeline,
		AppTimelineItem,
		vSelect,
	},
	directives: {
		Ripple,
	},
	data: () => ({
		logo: require('@/assets/images/logo/ferreysa.png'),
		logo_1: require('@/assets/images/logo/ferreysa_1.jpg'),
		logo_2: require('@/assets/images/logo/ferreysa_2.jpg'),
		logo_3: require('@/assets/images/logo/ferreysa_3.jpg'),
	}),
	computed: {
		canRequestMaintenance() {
			return this.canAccess(62) //Permisos para solicitar mantenimiento
		},
		countMaintenances() {
			return _.size(this.groupedMaintenances)
		},	
	},
	created() {
		// this.listenForMaintenanceCreated()
	},
	mounted() {
		this.listenForMaintenanceCreated()
	},
	methods: {
		listenForMaintenanceCreated() {
			window.Echo.channel('maintenances-channel')
				.listen('MaintenanceEvent', async (event) => {
					console.log(event.message);

					//Validamos se si finalizo o completo el mantenimiento
					const isCompleted = event.message.is_completed;

					// Obtener el truck_id del evento
					const truckId = event.message.truck_id;

					// Contar cuántas solicitudes de mantenimiento hay para este truck_id
					const count = this.maintenances.filter(item => item.truck_id === truckId).length;

					// Encontrar el camión en la lista de trucks
					const truck = this.trucks.find(item => item.id === event.message.truck.source_id);

					// Determinar el nuevo estatus basado en el número de solicitudes de mantenimiento
					// let status;
					// if (count > 1) {
					// 	status = 'En mantenimiento';
					// } else {
					// 	status = event.message.status === 'Completado' || event.message.status === 'Eliminado' ? 'Activo' : 'En mantenimiento';
					// }

					// Actualizar el estatus del camión
					// truck.estatus = status;
					truck.odometro = event.message.odometer;
					
					delete truck.conexiones

					if (isCompleted && event.message.type === 'Preventivo') {
						truck.ultimo_Servicio = new Date();
					}

					// Actualizar el camión en la API solo si es necesario
					// if (truck.estatus !== status) {
						const { data } = await WebCamionesResource.update(truck);
						if (data.isSuccesful) {

							// Actualizar la lista de trucks con el nuevo estatus
							// this.trucks = this.trucks.map(item => {
							// 	if (item.id === truck.id) {
							// 		item.estatus = status;
							// 		item.disabled = status === 'En mantenimiento';
							// 	}
							// 	return item;
							// });
						}
					// } else {
						// Si no se necesita actualizar la API, solo actualizamos el estado local
					// 	this.trucks = this.trucks.map(item => {
					// 		if (item.id === truck.id) {
					// 			item.estatus = status;
					// 			item.disabled = status === 'En mantenimiento';
					// 		}
					// 		return item;
					// 	});
					// }

					// Actualizar la lista de mantenimientos
					await this.getMaintenances();
				});
		},
		_listenForMaintenanceCreated() {
			window.Echo.channel('maintenances-channel')
			.listen('MaintenanceEvent', async (event) => {	
				
				console.log(event.message)	

				//Saber si existe mas de un mantenimiento en la lista basado en el truck_id
				const count = this.maintenances.filter(item => item.truck_id == event.message.truck_id).length		
				
				
				//Finded truck on trucks by source_id
				const truck = this.trucks.find(item => item.id == event.message.truck.source_id)
				const status = count > 1 ? 'En mantenimiento' : (event.message.status === 'Pendiente' ? 'En mantenimiento' : 'Activo')
				truck.estatus = status

				const { data } = await WebCamionesResource.update(truck)
				if (data.isSuccesful) {
					// if (status === 'Pendiente') {
					// 	this.maintenances.unshift(event.message)
					// } else {
						//Eliminar el item de la colección maintenances
						//this.maintenances = this.maintenances.filter(item => item.id != event.message.id)
						await this.getMaintenances()
					// }
				
					this.trucks = this.trucks.map(item => {
						if (item.id == truck.id) {
							item.estatus = status
							item.disabled = status === 'En mantenimiento'
						}
						return item
					})
				}
			});
		},
	},
	setup() {	

		const perfectScrollbarSettings = {
			maxScrollbarLength: 60,
			wheelPropagation: false,
		}

		const isActiveModalAddMaintenance = ref(false)
		const refModalListMaintenances = ref(null)
		const selectedMaintenances = ref([])
		const selectedTruck = ref(null)
		const statusTruckActuall = ref(null)

		const refModalMaintenance = ref(null)
		let modalInstance = null; // Instancia del modal Bootstrap

		const loading = ref(false)
        const maintenances = ref([])
		const trucks = ref([])
		const form = ref({
			user_id: 1, // ID del usuario
			truck_id: null,
			description: null,
			identifier: null,
			status: 'Pendiente',
			type: 'Preventivo',
			//company_id: store.getters['auth/getConexionId'],
			user_name: store.getters['auth/getUser'].userName,
			tag: 'Media',
			odometer: null,
		})
		const tags = ref([
			{ id: 'Baja', name: 'Baja', color: 'info' },
			{ id: 'Media', name: 'Media', color: 'warning' },
			{ id: 'Alta', name: 'Alta', color: 'danger' },
		])

		// Método para abrir el modal
		const openMaintenancesModal = (group) => {
			selectedTruck.value = group.truck
			selectedMaintenances.value = group.maintenances
			refModalListMaintenances.value.show()
		}

		const selectTruck = (evt) => {
			const truck = trucks.value.find(item => item.id == evt)
			if (truck) {
				form.value.odometer = truck.odometro
				statusTruckActuall.value = truck.estatus
			}
		}

		/**
		    1.- Filtra primero los mantenimientos pendientes
			2.- Calcula la fecha más antigua con Math.min para mejor performance
			3.- Ordena primero los mantenimientos dentro de cada grupo
			4.- Finalmente ordena los grupos por la fecha más antigua

			Resultado: 
				- Los camiones aparecerán ordenados del que tiene el mantenimiento más antiguo al más reciente
				- Dentro de cada grupo, los mantenimientos se mantienen ordenados por antigüedad
				- Optimizado para rendimiento con operaciones matemáticas directas
		 */
		const groupedMaintenances = computed(() => {
			const groups = maintenances.value
				.filter(m => m.status === 'Pendiente')
				.reduce((acc, maintenance) => {
				const key = maintenance.truck_id;
				if (!acc[key]) {
					acc[key] = {
						truck: {
							id: maintenance.truck_id,
							identifier: maintenance.identifier,
						},
						maintenances: [],
						oldestDate: Infinity
					};
				}
				
				const maintenanceDate = new Date(maintenance.created_at);
				acc[key].maintenances.push(maintenance);
				acc[key].oldestDate = Math.min(acc[key].oldestDate, maintenanceDate);

				return acc;
				}, {});

			// Ordenar mantenimientos internos y convertir a array
			return Object.values(groups)
				.map(group => ({
					...group,
					maintenances: group.maintenances.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
				}))
				.sort((a, b) => a.oldestDate - b.oldestDate);
		});		
		
		// Formatear las etiquetas para incluir la clase de color de BootstrapVue
		const formattedTags = computed(() =>
			tags.value.map(tag => ({
				id: tag.id,
				name: tag.name,
				class: `custom-control-${tag.color}`, // Clase de color dinámica
			}))
		);

		const getVariantMaintenance = (type) => {
			const variants = {
				'Preventivo': 'success',
				'Correctivo': 'danger',
			}
			return variants[type] || 'light-primary';
		}

		const getVariant = (tag) => {
			// const finded = tags.value.find(item => item.id == tag)
			// return finded ? finded.color : 'info'
			const variants = {
				'Media': 'light-warning',
				'Alta': 'light-danger',
				'Baja': 'light-success'
			}
			return variants[tag] || 'light-primary';
		}

		// Obtener la clase para el radio group
		const getClass = computed(() => 'custom-control');
      
        const loginInCloud = async () => {
			const payload = {
				grant_type: process.env.VUE_APP_API_PRICES_GRANT_TYPE,
				client_id: process.env.VUE_APP_API_PRICES_CLIENT_ID,
				client_secret: process.env.VUE_APP_API_PRICES_CLIENT_SECRET,
				scope: process.env.VUE_APP_API_PRICES_SCOPE,
			}
			try {			
				const { data } = await axios.post(`${process.env.VUE_APP_API_PRICES}/oauth/token`, payload, {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				});				

				if (data) {
					store.commit('auth/SET_TOKEN_CLOUD', data)
					localStorage.setItem('token_cloud', data.access_token)
				}
			}catch(e) {
				console.log(e)
			}
		}

        const getMaintenances = async () => {

            loading.value = true

            // Verifica si el usuario está autenticado
			if (!isAuthenticated()) {
				await loginInCloud() // Realiza el login si no está autenticado
			}

			const queryParts = [
				`status:Pendiente`, //company_id:${store.getters['auth/getConexionId']
			]

			const query = {
				search: queryParts.join(';'),
                searchFields: 'status:=', //';company_id:=',
                searchJoin: 'and',
				with: 'truck',
				orderBy: 'created_at',
				sortedBy: 'desc',
			}

            try {
                const { data } = await axios.get(`${process.env.VUE_APP_API_PRICES}/api/client/maintenances`, {
                    headers: {
                        Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
                        Accept: 'application/json',
                    },
					params: query
                })

				if (data.success) {
					maintenances.value = data.data
				} else {
					helper.danger(data.message)
				}

            }catch (error) {
                helper.handleResponseErrors(error)
            } finally {
                loading.value = false
            }
        }

		const getTrucksCloud = async () => {
			loading.value = true

			// Verifica si el usuario está autenticado
			if (!isAuthenticated()) {
				await loginInCloud() // Realiza el login si no está autenticado
			}

			const query = {
                with: 'company',
            }

			try {
				const { data } = await axios.get(`${process.env.VUE_APP_API_PRICES}/api/client/trucks`, {
					headers: {
						Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
						Accept: 'application/json',
					},
					params: query
			})

				if (data.success) {
					trucks.value = data.data
				} else {
					helper.danger(data.message)
				}

			} catch (error) {
				helper.handleResponseErrors(error)
			} finally {
				loading.value = false
			}
		}

		const showModalMaintenance = () => {
			refModalMaintenance.value.show()
		}

		const hideModal = () => {
			form.value = {
				user_id: 1, // ID del usuario
				truck_id: null,
				description: null,
				identifier: null,
				status: 'Pendiente',
				type: 'Preventivo',
				company_id: store.getters['auth/getConexionId'],
				user_name: store.getters['auth/getUser'].userName,
				tag: 'Media',
				odometer: null,
			}
			refModalMaintenance.value.hide()
		}

		const addMaintenance = async () => {
			loading.value = true

			const truck = trucks.value.find(item => item.id == form.value.truck_id)
			form.value.identifier = truck.label
			try {
				const { data } = await axios.post(`${process.env.VUE_APP_API_PRICES}/api/client/maintenances`, form.value, {
					headers: {
						Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
						Accept: 'application/json',
					},
				})				

				if (data.success) {

					//Encontramos el truck en la lista de mantenimientos
					// const exist = maintenances.value.find(item => item.truck.source_id == form.value.truck_id)
					// if (!exist) {
						//Actualizar el estatus del camión a En mantenimiento
						// truck.estatus = 'En mantenimiento'
						truck.odometro = form.value.odometer
						await WebCamionesResource.update(truck)
					// }

					helper.success(data.message)
					hideModal()

					//Agregar el nuevo item a la colección
					await getMaintenances()

				} else {
					helper.danger(data.message)
				}

			}catch(e) {
				helper.handleResponseErrors(e)
			} finally {
				loading.value = false
			}
		}

		/** En local (centra) */
		const getTrucks = async () => {
            if (trucks.value.length <= 0) {
                loading.value = true
                const { data } = await WebCamionesResource.index({})
                loading.value = false
                if (data.isSuccesful) {
                    trucks.value = data.data.map(item => ({
                        ...item, label: `#${item.identificador} ${item.marca} ${item.modelo} ${item.anio}`,
                        disabled: item.estatus === 'En mantenimiento', // Deshabilitar si está en mantenimiento
                    })).filter(item => item.activo && item.id != 1)
                }
            }
        }

		onMounted(async () => {
			await getTrucks()
			await getMaintenances()
		})

        return {
            loading,
            maintenances,
			perfectScrollbarSettings,
			trucks,
			refModalMaintenance,
			form,
			tags,
			selectedMaintenances,
			selectedTruck,
			refModalListMaintenances,
			statusTruckActuall,
			isActiveModalAddMaintenance,

			//
			formattedTags,
			getClass,
			groupedMaintenances,
			

            // Methods
			selectTruck,
			openMaintenancesModal,
			getVariantMaintenance,
			getMaintenances,
			getVariant,
			showModalMaintenance,
			hideModal,
			addMaintenance,
        }
	},
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.vehicle-group {
  transition: all 0.3s;
  border-left: 3px solid transparent;
  padding-left: 8px;
  
  &:hover {
    background-color: #f8f9fa;
  }
  
  &.active-group {
    border-left-color: #7367f0;
    background-color: #f8f9fa;
  }
}

.scrollable-container {
  max-height: 70vh;
  padding-right: 1rem;
}

.media {
  padding: 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s;
  background-color: #f8f9fa;
}

.media:hover {
  background-color: #e9ecef;
  transform: translateX(5px);
}

// .text-small {
//   font-size: 0.8rem;
// }
</style>

