<template>
	<div class="navbar-container main-menu-content">
		<horizontal-nav-menu-items :items="miMenus" />
	</div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default {
	components: {
		HorizontalNavMenuItems,
	},
	setup() {

		const miMenus = computed(() => {
			const user = store.getters['auth/getUser']
			const menus = user.permisos2

			console.warn('menus', menus)

			return menus
		})

		// const user = store.getters['auth/getUser']
		// const menus = user.permisos2

		// console.warn('menus', menus)

		return {
			miMenus,
			navMenuItems,
		}
	},
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
